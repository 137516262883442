import React, { useState, useEffect, useRef, cloneElement } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'

const Content = styled.div`
  position: absolute;
  overflow-y: hidden;
  max-height: 0;
  z-index: 999;
  &.show {
    max-height: 100%;
    transition-duration: 0.8s;
  }
  transition-property: max-height;
  transition-duration: 0.2s;
`

const Container = styled.div`
  width: 100%;
  position: relative;
`

const DropdownContent = ({ id, children }) => {
  if (typeof window === 'undefined') return null

  let element = useRef(
    document.getElementById(id) || document.createElement('div')
  )

  useEffect(() => {
    if (!document.getElementById(id)) {
      element.current.id = id
      document.body.appendChild(element.current)
    }

    return () => {
      if (element.current.parentElement) {
        element.current.parentElement.removeChild(element.current)
      }
    }
  }, [id])

  return createPortal(children, element.current)
}

const Dropdown = ({
  content,
  position,
  spacing = 0,
  fullWidth,
  children,
  closeOnClick,
}) => {
  const dropdown = useRef()
  const portal = useRef()
  const [open, setOpen] = useState(false)
  const [animation, setAnimation] = useState(false)
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 })

  const onOutSideClick = event => {
    if (
      dropdown.current.contains(event.target) ||
      (portal.current && portal.current.contains(event.target))
    ) {
      return
    }
    setAnimation(false)
    setTimeout(() => setOpen(false), 200)
  }

  useEffect(() => {
    if (open) {
      const button = dropdown.current.getBoundingClientRect()
      const content = portal.current.getBoundingClientRect()

      if (position === 'LeftTop') {
        setDropdownPosition({
          left: button.left,
          top: button.top + window.scrollY - (content.height + spacing),
        })
      } else if (position === 'RightTop') {
        setDropdownPosition({
          left: button.right - content.width,
          top: button.top + window.scrollY - (content.height + spacing),
        })
      } else if (position === 'RightBottom') {
        setDropdownPosition({
          left: button.right - content.width,
          top: button.bottom + window.scrollY + spacing,
        })
      } else {
        setDropdownPosition({
          left: button.left,
          top: button.bottom + window.scrollY + spacing,
        })
      }
    }
  }, [open])

  useEffect(() => {
    document.addEventListener('mousedown', onOutSideClick)

    return () => {
      document.removeEventListener('mousedown', onOutSideClick)
    }
  }, [])

  return (
    <Container ref={dropdown}>
      {cloneElement(children, {
        onClick: e => {
          e.stopPropagation()
          if (!open) {
            setTimeout(() => setAnimation(true), 210)
            setTimeout(() => setOpen(!open), 200)
          } else {
            setAnimation(false)
            setTimeout(() => setOpen(false), 200)
          }
        },
      })}
      {open && (
        <DropdownContent id="dropdownContent">
          <Content
            ref={portal}
            show={animation}
            className={classNames({ show: animation })}
            onClick={() => closeOnClick && setOpen(false)}
            style={{
              ...dropdownPosition,
              ...(fullWidth && { minWidth: dropdown.current.clientWidth }),
            }}>
            {content}
          </Content>
        </DropdownContent>
      )}
    </Container>
  )
}

Dropdown.propTypes = {
  content: PropTypes.element,
  position: PropTypes.string,
  children: PropTypes.element,
  spacing: PropTypes.number,
  fullWidth: PropTypes.bool,
  closeOnClick: PropTypes.bool,
}

export default Dropdown
